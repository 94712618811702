const initFadeSliders = {
  sliders: document.querySelectorAll( '.swiper' ),
  init: () => {
    if ( initFadeSliders.sliders.length === 0 ) return;

    initFadeSliders.sliders.forEach( slider => {
      const options = JSON.parse( slider.getAttribute( 'data-swiper' ) );
      if ( options.desktop?.effect == 'fade' ) {
        slider.style.opacity = '0';
        setTimeout( () => {
          slider.style.opacity = '1';
        }, 1500 );
      }
    } );
  }
};
initFadeSliders.init();
