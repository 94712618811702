( () => {
  const hideFiltersWhenEmpty = () => {
    const allFacetOptions = document.querySelectorAll( '.e-row--search-filter .fs-no-results' );
    const allFacetcheckboxes = document.querySelectorAll( '.facetwp-type-checkboxes' );
    if ( allFacetOptions.length > 0 ) {
      allFacetOptions.forEach( ( element ) => {
        if ( !element.classList.contains( 'fs-hidden' ) ) {
          element.closest( '.e-col' ).style.display = 'none';
        } else {
          element.closest( '.e-col' ).style.display = 'block';
        }
      } );
    }

    if ( allFacetcheckboxes.length > 0 ) {
      allFacetcheckboxes.forEach( accordion => {
        if ( accordion.querySelectorAll( '.facetwp-checkbox' ).length === 0 ) {
          accordion.closest( '.e-div' ).style.display = 'none';
        } else {
          accordion.closest( '.e-div' ).style.display = 'block';
        }
      } );
    }
  };

  document.addEventListener( 'facetwp-loaded', () => {
    setTimeout( () => {
      hideFiltersWhenEmpty();
    }, 0 );
  } );

  document.addEventListener( 'facetwp-loaded', () => {
    let templates = document.querySelectorAll( '.facetwp-template' );
    let noPostsFound = document.querySelectorAll( '.b3rg-posts .wp-block-b3rg-column' ).length === 0;

    // Loop through each template and add or remove the class based on the condition
    templates.forEach( template => {
      if ( noPostsFound ) {
        template.classList.add( 'no-posts-found' );
      } else {
        template.classList.remove( 'no-posts-found' );
      }
    } );
  } );

} ) ();
