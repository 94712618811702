import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
gsap.registerPlugin( ScrollTrigger );

const stickyContents = gsap.utils.toArray( '.e-div--focus-scroll-content' );

function setupScrollAnimation( stickyContents, images, section, stickySpace ) {
  stickyContents.forEach( ( stickyContent, index ) => {
    const startPosition = window.innerWidth < 991 ? 320 : 400;
    const cardHeight = stickyContent.offsetHeight;

    gsap.to( stickyContent, {
      duration: 1,
      scrollTrigger: {
        trigger: stickyContent,
        start: `top ${startPosition + ( stickySpace - 100 ) }`,
        end: `+=${cardHeight - 160}`,
        scrub: 0.8,
        invalidateOnResize: true,
        onEnterBack: () => updateImageClass( images, index, section ),
        onEnter: () => updateImageClass( images, index, section ),
      }
    } );
  } );
}

function updateImageClass( images, index, section ) {
  images.forEach( image => image.classList.remove( 'active' ) );
  section.querySelectorAll( 'video' ).forEach( vid => vid.pause() );
  images[index]?.classList.add( 'active' );
  const hasVideo = images[index]?.querySelector( 'video' );
  hasVideo?.play();
}

function updateMediaClassMobile( section, images ) {
  const swiperElement = section.querySelector( '.swiper' );
  swiperElement?.swiper?.on( 'slideChange', function() {
    updateImageClass( images, swiperElement.swiper.realIndex, section );
  } );
}

function initSvgAnimation( animationDOM, section ) {
  const svgObject = animationDOM?.querySelector( 'object' );
  if ( !svgObject ) return;

  const loadSvgElement = () => {
    const SVGDocument = svgObject.contentDocument || svgObject.contentWindow?.document;
    const player = SVGDocument.querySelector( 'svg' )?.svgatorPlayer || null;
    if ( player ) {
      player.stop();
      const onScroll = () => {
        const rect = section.getBoundingClientRect();
        const scrollPercentage = ( ( section.scrollTop + rect.y - ( window.innerHeight / 2 ) ) / -rect.height ) * 100;
        player.seek( scrollPercentage > 0 ? scrollPercentage + 10 : 0 );
      };
      window.addEventListener( 'scroll', onScroll );
    }
  };
  svgObject.addEventListener( 'load', loadSvgElement );
}

function updateStyle ( stickySpace ) {

  const newStyles = `
    .e-stn--focus-scroll-section .media-wrapper::before {
      height: ${ 170 + stickySpace}px;
      top: ${ -100 - stickySpace }px;
    }
  `;

  const styleSheet = document.createElement( 'style' );
  styleSheet.innerText = newStyles;
  document.head.appendChild( styleSheet );
}

function headerControl( header ) {
  const cloneHeader = header?.cloneNode( true );
  const getHeader = header.getBoundingClientRect();
  const getMargin = window.getComputedStyle( header );
  const stickySpace = getHeader.height + parseFloat( getMargin.marginBottom );
  cloneHeader.style.width = `${getHeader.width}px`;
  cloneHeader.style.marginTop = `-${stickySpace}px`;

  return { cloneHeader, stickySpace };
}

document.addEventListener( 'DOMContentLoaded', () => {
  document.querySelectorAll( '.e-stn--focus-scroll-section' ).forEach( section => {
    const images = section.querySelectorAll( '.e-div--media' );
    const animationDiv = section.querySelector( '.e-svg' );
    const mediaWrapper = document.createElement( 'div' );
    mediaWrapper.classList.add( 'media-wrapper' );
    const swiperSlides = section.querySelectorAll( '.swiper-slide' );
    const header = section.querySelector( '.e-row--section-title-with-description' );


    swiperSlides.forEach( slide => {
      const mediaElements = slide.querySelectorAll( '.e-div--media' );
      mediaElements.forEach( element => {
        const clonedElement = element.cloneNode( true );
        mediaWrapper.appendChild( clonedElement );
      } );
    } );
    
    if ( animationDiv ){
      mediaWrapper.appendChild( animationDiv );
    }

    const {cloneHeader, stickySpace} = headerControl( header );
    if ( cloneHeader && window.innerWidth > 767 ) {
      mediaWrapper.appendChild( cloneHeader );
      header.style.display = 'none';
    }

    const container = section.querySelector( '.swiper' );
    container?.appendChild( mediaWrapper );
    
    if ( cloneHeader && window.innerWidth > 767 ) {
      const getMedia = window.getComputedStyle( mediaWrapper );
      const getSection = window.getComputedStyle( section );
      mediaWrapper.style.top = `${parseFloat( getMedia.top ) + stickySpace}px`;
      section.style.setProperty( 'padding-top', `${parseFloat( getSection.paddingTop ) + stickySpace}px`, 'important' );
    }

    mediaWrapper.querySelectorAll( '.e-div--media' )[0]?.classList.add( 'active' );
    setupScrollAnimation( stickyContents, mediaWrapper.querySelectorAll( '.e-div--media' ), section, stickySpace );
    initSvgAnimation( animationDiv, section );
    updateImageClass( images, 0, section );
    updateMediaClassMobile( section, images );
    updateStyle( stickySpace );
  } );
} );

