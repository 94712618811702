document.addEventListener( 'DOMContentLoaded', function () {

  function cloneAndPrepareContentBlock( contentBlock ) {
    const clonedBlock = contentBlock.cloneNode( true );
    clonedBlock.classList.add( 'wp-block-b3rg-column', 'e-col', 'col-sm-12', 'col-lg-4' );

    return clonedBlock;
  }

  function prependContentBlock( sectionClass, rowClass ) {
    const section = document.querySelector( `.${sectionClass}` );
    if ( !section ) return;

    const row = section.querySelector( `.${rowClass}` );
    const contentBlock = section.querySelector( '.e-div--content-block' );
    if ( !contentBlock ) return;

    const preparedBlock = cloneAndPrepareContentBlock( contentBlock );
    row.prepend( preparedBlock );
  }

  function wrapElementsInSection( sectionClass ) {
    const section = document.querySelector( `.${sectionClass}` );
    if ( !section ) return;

    const sgpItems = section.querySelectorAll( '.e-sgp' );
    sgpItems.forEach( item => wrapSingleItem( item ) );
  }

  function wrapSingleItem( item ) {
    const mtfElement = item.querySelector( '.e-mtf' );
    const eventDateElement = item.querySelector( '.wp-block-event-date' );

    if ( !mtfElement || !eventDateElement ) return;

    const wrapperDiv = document.createElement( 'div' );
    wrapperDiv.classList.add( 'category-date-wrapper' );

    mtfElement.parentNode.insertBefore( wrapperDiv, mtfElement );
    wrapperDiv.appendChild( mtfElement );
    wrapperDiv.appendChild( eventDateElement );
  }

  function handleFacetUpdate() {
    wrapElementsInSection( 'e-stn--upcoming-events' );
    prependContentBlock( 'e-stn--upcoming-events', 'b3rg-posts .wp-block-b3rg-row.e-row' );
  }

  document.addEventListener( 'facetwp-loaded', handleFacetUpdate );
  prependContentBlock( 'e-stn--past-events', 'e-pst__post-grid' );

} );
