const expandPost = {
  activePost: false,

  init: () => {
    const listings = document.querySelectorAll( '.e-col--post-expand-view' );
    if ( listings.length === 0 ) return;
    listings.forEach( listing => {
      expandPost.setupExpandTriggers( listing );
    } );
  },

  updateURL: ( action, slug = null ) => {
    const newUrl = new URL( window.location.href );
    ( action === 'add' )
      ? newUrl.searchParams.set( 'highlight', slug )
      : newUrl.searchParams.delete( 'highlight' );
    window.history.replaceState( null, null, newUrl );
  },

  expandByURL: () => {
    const slug = new URLSearchParams( window.location.search )?.get( 'highlight' );
    const post = document.querySelector( `.e-col--post-expand-view [data-post-slug="${slug}"]` )?.nextSibling;
    if ( slug && slug !== '' && post ) {
      expandPost.expand( post );
    }
  },

  /**
   * Close expanded post
   */
  closeExpandedPost: () => {
    if ( expandPost.activePost === false ) return;
    expandPost.activePost.classList.remove( 'active' );
    expandPost.activePost.previousElementSibling?.classList.remove( 'active' );
    expandPost.activePost = false;
    expandPost.updateURL( 'remove', null );
    document.body.classList.remove( 'mobile-filter-opened' );
  },

  /**
   * Expand post
   * @param { Element } post
   */
  expand: ( post ) => {
    const contentElement = post.previousElementSibling;
    if ( !contentElement?.classList.contains( 'fancybox__content' ) ) return;

    if ( window.innerWidth < 768 ) {
      document.body.classList.add( 'mobile-filter-opened' );
    }

    const postSlug = contentElement.getAttribute( 'data-post-slug' ),
      postRow = parseInt( post.getAttribute( 'data-row' ) ),
      colSpan = window.innerWidth >= 992 ? 3 : 2;

    if ( !contentElement.querySelector( '.close-post-view' ) ) {
      const closeBtn = document.createElement( 'span' );
      closeBtn.classList.add( 'close-post-view' );
      closeBtn.addEventListener( 'click', expandPost.closeExpandedPost );
      contentElement.appendChild( closeBtn );
    }

    window.scrollTo( {
      top: post.getBoundingClientRect().top + window.scrollY - 100,
      behavior: 'smooth'
    } );

    expandPost.updateURL( 'add', postSlug );
    contentElement.classList.add( 'active' );
    contentElement.style.gridArea = `${postRow + 1} / 1 / span 1 / span ${colSpan}`;
    post.classList.add( 'active' );
    expandPost.activePost = post;
  },

  /**
   * Setup click trigger
   * @param { Element } DOM 
   */
  setupExpandTriggers: ( DOM ) => {
    expandPost.initSliders( DOM );
    const posts = DOM.querySelectorAll( '.b3rg-posts > .wp-block-b3rg-row > .e-col' );
    posts.forEach( ( post, i ) => {
      const cols = window.innerWidth >= 992 ? 3 : 2;
      post.setAttribute( 'data-row', Math.floor( i / cols ) + 1 );
      post.addEventListener( 'click', ( e ) => {
        e.preventDefault();
        expandPost.closeExpandedPost();
        expandPost.expand( post );
      } );
    } );
  },

  /**
   * Initialize swiper sliders on facet load
   * @param {Element} DOM 
   */
  initSliders: ( DOM ) => {
    let sliders = DOM.querySelectorAll( '.swiper' );
    sliders.forEach( slider => {
      if ( !slider.classList.contains( 'swiper-initialized' ) ) {
        const options = JSON.parse( slider.getAttribute( 'data-swiper' ) );
        const settings = window.innerWidth >= 992 ? options['desktop'] : options['mobile'];
        /* eslint-disable */
        const swiper = new Swiper( slider, settings ); // NOSONAR
        /* eslint-enable */
      }
    } );
  }
};

document.addEventListener( 'facetwp-loaded', () => {
  expandPost.init();
  expandPost.expandByURL();
} );
