
document.addEventListener( 'DOMContentLoaded', function() {
  const breakpoint = 992; 
  const testimonialSliderSection = document.querySelector( '.e-stn--full-width-testimonial-slider' );
  if ( testimonialSliderSection ) {
    const swiperSlides = testimonialSliderSection.querySelectorAll( '.swiper-slide' );
    const moveRightContent = () => {
      const isSmallScreen = window.innerWidth < breakpoint;
      swiperSlides.forEach( slide => {
        const mediaWrapper = slide.querySelector( '.e-div--media-wrapper' );
        const contentWrapper = slide.querySelector( '.e-div--content-wrapper' );
        const rightContent = slide.querySelector( '.e-div--right-content' );
        if ( mediaWrapper && contentWrapper && rightContent ) {
          if ( isSmallScreen ) {
            mediaWrapper.appendChild( rightContent );
          } else {
            contentWrapper.appendChild( rightContent );
          }
        }
      } );
    };
    moveRightContent();
    window.addEventListener( 'resize', moveRightContent );
  }
} );
