class StickyTabs {
  constructor( selector ) {
    this.stickyTabs = document.querySelectorAll( selector );
    if ( this.stickyTabs.length === 0 ) return;
    if ( window.innerWidth < 992 ) return;
    this.tabsLength = this.stickyTabs.length;

    this.setTabEqualHeight();

    this.checkSticky = this.checkSticky.bind( this );
    window.addEventListener( 'scroll', this.checkSticky );
    window.addEventListener( 'load', this.checkSticky );
  }

  setTabEqualHeight() {
    let maxHeight = 0;
    this.stickyTabs.forEach( stickyTab => {
      const tempHeight = stickyTab.offsetHeight;
      if ( maxHeight < tempHeight ) {
        maxHeight = tempHeight;
      }
    } );

    this.stickyTabs.forEach( stickyTab => {
      stickyTab.style.minHeight = `${maxHeight}px`;
    } );
  }

  stickyTabObserver( tab ) {
    const observer = new IntersectionObserver( ( entries ) => {
      entries.forEach( ( entry ) => {
        const top = entry.target.getBoundingClientRect().top;
        const tabTop = parseInt( window.innerHeight / 6.5 );
        entry.target.style.top = `${tabTop}px`;
        if ( top <= tabTop ) {
          entry.target.classList.add( 'sticky' );
        } else {
          entry.target.classList.remove( 'sticky' );
        }
      } );
    }, {
      root: null,
      threshold: 0
    } );

    observer.observe( tab );
  }

  checkSticky() {
    this.stickyTabs.forEach( ( card, index ) => {
      card.setAttribute( 'data-pos', this.tabsLength - ( index + 1 ) );
      this.stickyTabObserver( card );
    } );
  }
}

const stickyTabs = new StickyTabs( '.e-row--on-scroll-tabs .swiper-slide' );
document.addEventListener( 'DOMContentLoaded', stickyTabs );
