// Function to set the height of right content based on left content's height
function setHeight() {
  const sliderSection = document.querySelector( '.e-stn--vertical-card-slider' );
  if ( !sliderSection ) return;

  const leftContent = sliderSection.querySelector( '.e-div--left-content' );
  const rightContent = sliderSection.querySelector( '.e-div--right-content' );
  if ( !leftContent || !rightContent ) return;

  if ( window.innerWidth <= 992 ) {
    rightContent.style.height = 'auto';
  } else {
    const leftHeight = leftContent.offsetHeight;
    rightContent.style.height = `${leftHeight}px`;
  }
}

// Function to get the CSS gap value
function getGapValue( element ) {
  const style = window.getComputedStyle( element );
  const gap = style.getPropertyValue( 'gap' ) || style.getPropertyValue( 'row-gap' ) || '0px';
  return parseInt( gap );
}

// Function to clone the first child element and set the translateY value
function cloneAndSetTranslateY( div ) {
  const child = div.firstElementChild;
  if ( !child ) return;

  const clonedChild = child.cloneNode( true );
  child.parentNode.insertBefore( clonedChild, child.nextSibling );

  const gapValue = getGapValue( div );

  const clonedChildHeight = clonedChild.offsetHeight + gapValue;
  div.style.setProperty( '--translateY', `-${clonedChildHeight}px` );
}

// Function to remove all cloned children
function removeClones( div ) {
  const children = Array.from( div.children );
  children.forEach( ( child, index ) => {
    if ( index > 0 ) {
      child.remove();
    }
  } );
}

// Function to apply cloneAndSetTranslateY to multiple selectors
function applyAutoscrollTransform( ...selectors ) {
  selectors.forEach( selector => {
    const autoscrollDivs = document.querySelectorAll( selector );
    autoscrollDivs.forEach( div => {
      if ( window.innerWidth > 992 ) {
        cloneAndSetTranslateY( div );
      } else {
        removeClones( div );
      }
    } );
  } );
}

window.onload = () => {
  setHeight();
  applyAutoscrollTransform(
    '.e-stn--vertical-card-slider .e-div--v-card-slider',
    '.e-stn--vertical-card-slider .e-div--v-card-slider-reverse'
  );
};

// Add event listener for window resize to adjust the height and clones dynamically
window.addEventListener( 'resize', () => {
  setHeight();
  applyAutoscrollTransform(
    '.e-stn--vertical-card-slider .e-div--v-card-slider',
    '.e-stn--vertical-card-slider .e-div--v-card-slider-reverse'
  );
} );
