export default class MobileAccordion {
  constructor( section ) {
    this.section = section;
    this.currentActiveIndex = 0;
    this.accordionItems = [];
    this.buildLayout();
    this.handleAccordion = this.handleAccordion.bind( this );
  }
  
  buildLayout() {
    const accordionContents = this.section?.querySelectorAll( '.e-tbs__content .swiper-slide' );
    const sliderNavs = this.section?.querySelectorAll( '.e-tbs__nav .swiper-slide' );
    accordionContents.forEach( ( content, index ) => {
      const title = sliderNavs[index]?.cloneNode( true );
      title.classList.add( 'accordion-title' );
      content.prepend( title );
    } );
  }

  handleAccordion() {
    this.handleAccordionHeights( 
      '.e-tbs__content .swiper-slide:not(.accordion-title)', '.e-tbs__content .accordion-title' 
    );
    this.handleDefaultActiveAccordion();
    this.setupAccordion();
  }

  handleDefaultActiveAccordion() {
    const defaultAccordion = this.accordionItems[this.currentActiveIndex]?.content;
    this.accordionItems.forEach( ( obj ) => {
      obj.content.style.height = obj.titleHeight+'px';
    } );
    defaultAccordion.style.height = ( this.accordionItems[this.currentActiveIndex].contentHeight )+'px';
    defaultAccordion.classList.add( 'active' );
  }

  handleAccordionHeights( content, nav ) {
    const accordionContents = this.section?.querySelectorAll( content );
    const accordionTitles = this.section?.querySelectorAll( nav );
    accordionContents.forEach( ( content, index ) => {
      this.accordionItems.push( {
        content: content,
        contentHeight: content.clientHeight+12,
        title: accordionTitles[index],
        titleHeight: accordionTitles[index].clientHeight+2
      } );
    } );
    
  }

  setupAccordion() {
    this.accordionItems.forEach( ( item, index ) => {
      item.title.addEventListener( 'click', () => {
        const previousActiveItem = this.accordionItems[this.currentActiveIndex]?.content;
        previousActiveItem.classList.remove( 'active' );
        previousActiveItem.style.height = this.accordionItems[this.currentActiveIndex].titleHeight + 'px';
        item.content.classList.add( 'active' );
        item.content.style.height = ( item.contentHeight ) + 'px';
        this.currentActiveIndex = index;
      } );
    } );
  }
}

const tabSliders = document.querySelectorAll( '.e-stn--tab-slider' );
tabSliders.forEach( ( tabSlider ) => {
  const contentSlider = tabSlider.querySelector( '.e-tbs__content' );
  const settings = JSON.parse( contentSlider.getAttribute( 'data-swiper' ) );

  const mobile = ( settings['mobile'].destroy ) ? 540 : null ;
  const tabletPortrait = ( settings['tablet-portrait'].destroy ) ? 992 : null ;
  const tabletLandscape = ( settings['tablet-landscape'].destroy ) ? 1200 : null ;
  const desktop = ( settings['desktop'].destroy ) ? 4500 : null ;

  const maxValue = Math.max(
    mobile ?? -Infinity,
    tabletPortrait ?? -Infinity,
    tabletLandscape ?? -Infinity,
    desktop ?? -Infinity
  );
  if( screen.width > maxValue ) return;
  const mobileAccordion = new MobileAccordion( tabSlider );
  document.addEventListener( 'DOMContentLoaded', mobileAccordion.handleAccordion );
} );
