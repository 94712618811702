const headerFuncs = {
  // Sticky header on scroll
  makeSticky: () => {
    const header = document.querySelector( 'header' );
    window.onscroll = () => {
      if ( window.scrollY >= 80 ) {
        header.classList.add( 'minimize' );
      } else {
        header.classList.remove( 'minimize' );
      }
    };
  },

  // Trigger Search popup
  searchPopup: () => {
    const searchForm = document.querySelector( 'header .wp-block-search' );
    if ( !searchForm ) return;

    const trigger = document.querySelector( 'header > div > div > .wp-block-b3rg-icon-block' );
    trigger.addEventListener( 'click', () => {
      searchForm.classList.add( 'active' );
      searchForm.querySelector( 'input' ).focus();
      searchForm.querySelector( 'input' ).value = '';
      document.querySelector( 'body' ).classList.add( 'open-search' );
    } );

    document.querySelector( 'header .e-div--close' ).addEventListener( 'click', ( e ) => {
      if ( e.target.tagName.toLowerCase() !== 'input' ) {
        searchForm.classList.remove( 'active' );
        document.querySelector( 'body' ).classList.remove( 'open-search' );
      }
    } );
  },

  // Insret header top menu and CTA into mobile dropdown
  updateMobileDropdown: () => {
    const mainMenu = document.getElementById( 'mega-menu-primary_menu' );
    const topNav = document.querySelectorAll( 'header nav.wp-block-navigation ul.wp-block-navigation' );
    const insertDOM = document.createElement( 'div' );
    insertDOM.classList.add( 'appended-menu-dom' );

    if ( topNav.length > 0 ) {
      const clonedNav = topNav[0].cloneNode( true );
      insertDOM.appendChild( clonedNav );
    }

    let isAppended = false;

    const onResize = () => {
      if ( window.innerWidth <= 1199 && !isAppended ) {
        if ( !mainMenu.querySelector( '.appended-menu-dom' ) ) {
          mainMenu.appendChild( insertDOM );
        }
        isAppended = true;
      } else if ( window.innerWidth > 1199 && isAppended ) {
        if ( mainMenu.querySelector( '.appended-menu-dom' ) ) {
          mainMenu.removeChild( insertDOM );
        }
        isAppended = false;
      }
    };

    window.addEventListener( 'resize', onResize );
    onResize();
  }
};

document.addEventListener( 'DOMContentLoaded', () => {
  headerFuncs.makeSticky();
  headerFuncs.searchPopup();
  headerFuncs.updateMobileDropdown();
} );
