export function setEqualHeight( outerSelector, innerSelector ) {
  document.addEventListener( 'DOMContentLoaded', function () {
    // Get all sections with the specified outer selector
    const sections = document.querySelectorAll( outerSelector );
    if ( !sections.length ) return; // Exit if no sections are found

    sections.forEach( section => {
      // Get all elements with the specified inner selector within the section
      const elements = section.querySelectorAll( innerSelector );

      // Find the maximum height
      let maxHeight = 0;
      elements.forEach( element => {
        const height = element.offsetHeight;
        if ( height > maxHeight ) {
          maxHeight = height;
        }
      } );

      // Set the height of all elements to the maximum height
      elements.forEach( element => {
        element.style.height = maxHeight + 'px';
      } );
    } );
  } );
}

export function setEqualHeightWithCondition(
  outerSelector, 
  innerSelector, 
  conditionCallback = () => window.innerWidth > 768, 
  debounceTime = 100 
) {
  // Debounce function to limit how often a function is called
  function debounce( func, wait ) {
    let timeout;
    return function( ...args ) {
      const context = this;
      clearTimeout( timeout );
      timeout = setTimeout( () => func.apply( context, args ), wait ); 
    };
  }

  function applyEqualHeight() {
    // Evaluate the condition using the provided callback
    let condition;
    try {
      condition = conditionCallback();
    } catch ( error ) {
      console.error( 'Error evaluating condition:', error );
      return;
    }

    const sections = document.querySelectorAll( outerSelector );

    // If no sections are found, exit early
    if ( !sections.length ) {
      console.warn( 'No elements found for selector:', outerSelector );
      return;
    }

    // Reset the height of all elements first
    sections.forEach( section => {
      const elements = section.querySelectorAll( innerSelector );
      elements.forEach( element => {
        element.style.height = ''; // Reset to default height
      } );
    } );

    // If the condition is not met, exit without setting new heights
    if ( !condition ) {
      return;
    }

    // Apply equal height logic
    sections.forEach( section => {
      const elements = section.querySelectorAll( innerSelector );

      if ( !elements.length ) {
        console.warn( 'No elements found for inner selector:', innerSelector );
        return;
      }

      // Find the maximum height
      let maxHeight = 0;
      elements.forEach( element => {
        const height = element.offsetHeight;
        if ( height > maxHeight ) {
          maxHeight = height;
        }
      } );

      // Set the height of all elements to the maximum height
      elements.forEach( element => {
        element.style.height = `${maxHeight}px`;
      } );
    } );
  }

  // Apply equal height on DOM content loaded
  document.addEventListener( 'DOMContentLoaded', applyEqualHeight );

  // Reapply equal height on window resize with debounce
  window.addEventListener( 'resize', debounce( applyEqualHeight, debounceTime ) );
}