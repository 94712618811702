const buildLeverAPI = {
  section: document.querySelector( '.e-stn--job-post .e-div--top-content' ),

  /**
   * Single job post API request
   */
  getJob: async function () {
    if ( !buildLeverAPI.section ) return;

    const queryParam = new URLSearchParams( window.location.search );
    if ( !queryParam.has( 'id' ) ) {
      window.location = '/';
    }

    try {
      const response = await fetch( `https://api.lever.co/v0/postings/genesis/${queryParam.get( 'id' )}` );
      if ( !response.ok ) {
        throw new Error( `Response status: ${response.status}` );
      }
      const data = await response.json();
      buildLeverAPI.buildJobDOM( data );
    } catch ( error ) {
      console.error( error.message );
    }
  },

  /**
   * Build single job layout
   * @param {Object} data 
   */
  buildJobDOM: ( data ) => {
    let listsDOM = '';
    data.lists.forEach( list => {
      listsDOM += `<h3 class="list-title">${list.text}</h3>`;
      listsDOM += `<ul>${list.content}</ul>`;
    } );

    const applyBtnText = document.querySelector( '.apply-btn-text' );
    const applyButton = `
    <div class="e-btn e-btn--primary">
      <a class="e-btn__link" href="${data.applyUrl}" target="_blank">
        <p class="title">${applyBtnText.textContent}</p>
      </a>
    </div>`;

    let DOM = `
      <div class="job-header">
        <div class="job-header__content">
          <h2 class="job-title">${data.text}</h2>
          <span class="location">${data.categories.location}</span>
          <span class="spec">
            ${data.categories.department} -
            ${data.categories.team} / ${data.categories.commitment} / ${data.workplaceType}
          </span>
        </div>
        ${applyButton}
      </div>
      <div class="job-description">${data.description}</div>
      <div class="job-lists">${listsDOM}</div>
      <div class="job-additional">${data.additional}</div>
      <br><br>
      <div class="last-button">${applyButton}</div>
    `;
    buildLeverAPI.section.innerHTML = DOM;
  }
};

( () => {
  buildLeverAPI.getJob();
} )();
