class TableContent {
  constructor( section ) {
    this.selector = document.querySelector( section );
    if ( !this.selector ) return;

    this.tableOfContent = this.selector.querySelector( '.e-col--table-of-content' );
    this.links = this.tableOfContent.querySelectorAll( 'ul a' );
    this.contents = this.selector.querySelectorAll( '.wp-block-heading' );
    this.header = document.querySelector( 'header' );
    this.headerHeight = this.header.offsetHeight;
    this.contentPosition = [];
    this.createProgressBar();
    this.getContentPosition();
    this.bindTableContentClick();
    this.bindNavigateToForm();
    this.setTableContentSticky();
    this.handleScroll = this.handleScroll.bind( this );
    window.addEventListener( 'scroll', this.handleScroll );
  }

  handleScroll() {
    this.updateProgressBar();
    this.setActiveTableContent();
  }

  bindTableContentClick() {
    this.links.forEach( link => {
      link.addEventListener( 'click', ( event ) => {
        const href = link.getAttribute( 'href' ).substring( 1 );
        event.preventDefault();
        this.scrollToSection( href );
      } );
    } );
  }

  getContentPosition() {
    this.contents.forEach( content => {
      const id = content.getAttribute( 'id' );
      const positions = content.getBoundingClientRect().top - ( this.headerHeight + 50 ) ;
      if( id === null ) return;
      this.contentPosition.push( {
        id: id,
        position: positions
      } );
    } );
  }

  scrollToSection( href ) {
    this.contentPosition.forEach( array => {
      if ( array.id !== href ) return;
      this.windowScroller( array.position );
    } );
  }

  createProgressBar() {
    const div = document.createElement( 'div' );
    div.classList.add( 'progress-bar' );
    this.header.append( div );
  }

  setActiveTableContent() {
    const scrollTop = window.scrollY + this.header.offsetHeight + 10;
    let activeId = '';
    for ( const item of this.contentPosition ) {
      if ( scrollTop >= item.position ) {
        activeId = item.id;
      }
    }

    this.links.forEach( link => {
      const href = link.getAttribute( 'href' ).substring( 1 );
      if ( href === activeId ) {
        link.classList.add( 'active' );
      } else {
        link.classList.remove( 'active' );
      }
    } );
  }

  updateProgressBar() {
    const scrollTop = window.scrollY;
    const scrollHeight = this.selector.nextElementSibling.offsetTop;
    const nextSectionHeight = this.selector.nextElementSibling.getBoundingClientRect().height / 2;
    const scrolled = ( scrollTop / ( scrollHeight - nextSectionHeight ) ) * 100;
    document.documentElement.style.setProperty( '--scroll-progress', scrolled + '%' );
  }

  setTableContentSticky() {
    const tableContentHeight = this.tableOfContent.getBoundingClientRect().height;
    const windowHeight = window.innerHeight - ( this.headerHeight + 150 );
    if( tableContentHeight > windowHeight ) {
      this.tableOfContent.classList.add( 'remove-sticky' );
    }
  }

  bindNavigateToForm() {
    this.tableOfContent.querySelector( '.e-btn__link' ).addEventListener( 'click', ( event ) => {
      event.preventDefault();
      const form = document.querySelector( '.e-stn--cta-with-form' );
      if ( form ) {
        const formPosition = form.offsetTop - ( this.headerHeight + 50 );
        this.windowScroller( formPosition );
      }
    } );
  }

  windowScroller( scrollPosition ) {
    window.scrollTo( {
      top: scrollPosition,
      behavior: 'smooth'
    } );
  } 
}

const tableContent = new TableContent( '.e-stn--inner-content' );
document.addEventListener( 'DOMContentLoaded', tableContent );

