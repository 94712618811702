
import { slideDown, slideUp } from './common';

document.addEventListener( 'DOMContentLoaded', function () {
  const duration = 600;
  const footer = document.querySelector( 'footer' );

  const menuItems = footer.querySelectorAll( 'ul.wp-block-navigation > .wp-block-navigation-item.has-child' );
  menuItems.forEach( function ( menuItem ) {
    const span = document.createElement( 'span' );
    span.classList.add( 'footer-drop-down-helper' );
    menuItem.appendChild( span );
  } );

  const footerDropDownHelpers = footer.querySelectorAll(
    'ul.wp-block-navigation > .wp-block-navigation-item.has-child span.footer-drop-down-helper'
  );

  footerDropDownHelpers.forEach( function ( helper ) {
    helper.addEventListener( 'click', function ( e ) {
      const li = e.target.parentElement;
      const isExpanded = li.classList.contains( 'footer-expanded' );

      const expandedItems = footer.querySelectorAll( '.footer-expanded' );
      expandedItems.forEach( function ( expandedItem ) {
        const subMenu = expandedItem.querySelector( 'wp-block-navigation__submenu-container' );
        if ( subMenu ) {
          slideUp( subMenu, duration );
          expandedItem.classList.remove( 'footer-expanded' );
        }
      } );
      const subMenu = e.target.previousElementSibling;
      if ( !subMenu ) return;

      if ( isExpanded ) {
        slideUp( subMenu, duration );
        subMenu.parentElement.classList.remove( 'footer-expanded' );
      } else {
        li.classList.add( 'footer-expanded' );
        slideDown( subMenu, duration );
      }
    } );
  } );
} );