class HistoryCard {
  constructor( section ) {
    this.section = section;
    this.tabSlider = this.section.querySelector( '.tab-slider' );
    this.tabEl = this.section.querySelector( '.tabs' );
    this.tabs = this.tabEl.querySelectorAll( 'li' );

    this.prevActiveTabId = 0;
    this.tabs[this.prevActiveTabId].classList.add( 'active' );
  }

  init() {
    this.responsiveNavPanel();
    this.sliderChangeEvent();
    this.navTabClick();
  }

  navTabClick() {
    this.tabs.forEach( ( tab, index ) => {
      tab.addEventListener( 'click', () => {
        const contentSwiper = this.tabSlider.querySelector( '.swiper' ).swiper;
        contentSwiper.slideTo( index, 300, true );
      } );
    } );
  }
  
  responsiveNavPanel() {
    const tabsWrapper = this.tabSlider.querySelector( '.tabs-wrapper' );
    const sliderHeight = this.tabSlider.querySelector( '.tab-content' ).offsetHeight + 150;
    const navItemHeight = 48;
    const totalCards = this.tabs.length;
    const heightDif = tabsWrapper.offsetHeight - ( sliderHeight - 48 );
    const noneVisibleTabs = Math.ceil( heightDif / navItemHeight );
    const centerTabId = Math.round( ( totalCards - noneVisibleTabs ) / 2 );


    this.centerTabId = centerTabId;
    this.noneVisibleTabs = noneVisibleTabs;

    tabsWrapper.style.height = ( sliderHeight - 80 ) + 'px';
    this.tabEl.style.height = ( sliderHeight - 80 ) + 'px';
    this.tabEl.classList.add( 'has-more-years' );
  }

  navPanelControl() {
    if ( this.prevActiveTabId >= this.centerTabId ) {
      for( let a = 0; a < this.noneVisibleTabs; a++ ) {
        this.tabs[a]?.classList.add( 'hide' );
        this.tabEl.classList.remove( 'has-more-years' );
      }
    } else if ( this.prevActiveTabId < this.centerTabId ) {
      for( let a = 0; a < this.noneVisibleTabs; a++ ) {
        this.tabs[a]?.classList.remove( 'hide' );
        this.tabEl.classList.add( 'has-more-years' );
      }
    }
  }

  sliderChangeEvent() {
    const contentSwiper = this.tabSlider.querySelector( '.swiper' ).swiper;
    contentSwiper.on( 'slideChange', () => {
      const activeIndex = contentSwiper.activeIndex;
      this.tabs[this.prevActiveTabId].classList.remove( 'active' );
      this.tabs[activeIndex].classList.add( 'active' );
      this.prevActiveTabId = activeIndex;
      this.navPanelControl();
    } );
  }
}

const cardSlider = document.querySelector( '.e-stn--history-card' );
if ( cardSlider ) {
  const historyCard = new HistoryCard( cardSlider );
  document.addEventListener( 'DOMContentLoaded', () => historyCard.init() );
}
