( () => {
  const filterAccordion = document.querySelector( '.e-col--filter-accordion' );
  if ( !filterAccordion ) return;

  const accordions = filterAccordion.querySelectorAll( '.e-div--filter-accordion-wrapper > .e-div' );
  accordions[0].classList.add( 'active' );

  accordions.forEach( accordion => {
    const trigger = accordion.querySelector( ':scope > p' );
    trigger.addEventListener( 'click', ( e ) => {
      e.preventDefault();
      accordion.classList.toggle( 'active' );
    } );
  } );

  // Mobile popup
  const mobileTrigger = document.querySelector( '.e-col--filter-accordion > .e-btn' );
  const mobileWrapper = document.querySelector( '.e-div--filter-accordion-wrapper' );
  const filterBtn = mobileWrapper.querySelector( '.e-btn--primary' );

  const closeBtn = document.createElement( 'span' );
  closeBtn.classList.add( 'close-filter' );
  mobileWrapper.querySelector( ':scope > p' ).appendChild( closeBtn );

  const openFilter = ( e ) => {
    e.preventDefault();
    document.body.classList.add( 'mobile-filter-opened' );
    mobileWrapper.classList.add( 'active' );
  };

  const closeFilter = ( e ) => {
    e.preventDefault();
    document.body.classList.remove( 'mobile-filter-opened' );
    mobileWrapper.classList.remove( 'active' );
  };
  
  mobileTrigger.addEventListener( 'click', openFilter );
  closeBtn.addEventListener( 'click', closeFilter );
  filterBtn.addEventListener( 'click', closeFilter );
} ) ();
