document.addEventListener( 'DOMContentLoaded', () => {
  let closeButtons = document.querySelectorAll( '.b3rg-notification .b3rg-notification__close' );
  closeButtons.forEach( ( closeButton ) => {
    closeButton.addEventListener( 'click', ( event ) => {
      // Disable the close button
      event.target.disabled = true;
      const notificationId = event.target.getAttribute( 'data-notification-id' );
      const xhr = new XMLHttpRequest();
      xhr.open( 'POST', b3rgScriptData.ajaxurl, true ); // eslint-disable-line no-undef
      xhr.setRequestHeader( 'Content-Type', 'application/x-www-form-urlencoded' );
      xhr.onload = () => {
        if ( xhr.status >= 200 && xhr.status < 400 ) {
          // Removes the notification when cookie creation is done
          event.target.closest( '.b3rg-notification' ).remove();
        } else {
          // We reached our target server, but it returned an error
          console.error( xhr.responseText );
        }
        // Re-enable the close button
        event.target.disabled = false;
      };
      xhr.onerror = () => {
        // There was a connection error of some sort
        console.error( 'Connection error occurred' );
        // Re-enable the close button
        event.target.disabled = false;
      };
      xhr.send( encodeURI( 'action=b3rg_create_notification_cookie&notification_id=' + notificationId ) );
    } );
  } );

  // Animate lengthy text
  const makeMarqueeAndLink = () => {
    const notificationBar = document.querySelector( '.b3rg-notification' );
    if ( !notificationBar ) return;

    const text = notificationBar.querySelector( '.text-content' );
    if ( text.scrollWidth > text.parentElement.clientWidth ) {
      text.parentElement.appendChild( text.cloneNode( true ) );
      text.parentElement.classList.add( 'marquee' );
    }

    if ( notificationBar.querySelectorAll( 'a' ).length > 0 ) {
      const text = notificationBar.querySelector( '.b3rg-notification__text' );
      text.style.cursor = 'pointer';
      text.addEventListener( 'click', () => {
        notificationBar.querySelectorAll( 'a' )[0].click();
      } );
    }
  };

  makeMarqueeAndLink();
} );
