document.addEventListener( 'DOMContentLoaded', () => {
  window.addEventListener( 'load', () => {
    const wrapperElement = document.querySelector( '.heateor_sss_button_copy_link' );
    if ( wrapperElement ) {
      const copiedText = 'Link Copied';
      const tooltip = document.createElement( 'div' );
      tooltip.className = 'ss-tooltip';
      tooltip.innerHTML = `<p>${copiedText}</p>`;
      wrapperElement.appendChild( tooltip );

      wrapperElement.addEventListener( 'click', ( ) => {
        const tooltip = document.querySelector( '.ss-tooltip' );
        if ( tooltip ) {
          tooltip.classList.add( 'active' );
          setTimeout( () => {
            tooltip.classList.remove( 'active' );
          }, 1500 );
        }
      } );
    }
  } );
} );
