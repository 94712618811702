document.addEventListener( 'DOMContentLoaded', () => {
  const nextButton = document.querySelector( '.e-stn--cards-slider .swiper-button-next' );
  const prevButton = document.querySelector( '.e-stn--cards-slider .swiper-button-prev' );
  
  if ( nextButton && prevButton ) {
    const buttonWrapper = document.createElement( 'div' );
    buttonWrapper.classList.add( 'swiper-btn-wrapper' );
    buttonWrapper.appendChild( nextButton );
    buttonWrapper.appendChild( prevButton );
  
    const rightContentBlock = document.querySelector( '.e-stn--cards-slider .e-div--right-content' );
    if ( rightContentBlock ) {
      rightContentBlock.appendChild( buttonWrapper );
    }
  }
} );
  