import Swiper from 'swiper/bundle';
import 'swiper/swiper-bundle.css';

document.addEventListener( 'DOMContentLoaded', () => {
  document.querySelectorAll( 
    '.e-featured-slider .swiper[data-swiper], .e-stn--history-card .swiper[data-swiper]' 
  ).forEach( swiperElement => {
    const swiperConfig = JSON.parse( swiperElement.getAttribute( 'data-swiper' ) ) || {};
    new Swiper( swiperElement, swiperConfig ); // NOSONAR
  } );
} );
