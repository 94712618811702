const svgator = {

  /**
   * Initialize hover animation on sections
   */
  initHover: () => {
    const sections = document.querySelectorAll( '.e-stn--svgator-hover' );
    if ( sections.length === 0 ) return;

    sections.forEach( section => {
      const links = section.querySelectorAll( 'a' );
      if ( links.length === 0 ) return;

      links.forEach( link => {
        svgator.bindHoverEvent( link );
      } );
    } );
  },

  /**
  * Bind hover event
  * @param {Element} link 
  */
  bindHoverEvent: ( link ) => {
    const svgObject = link.querySelector( '.e-svg object' ) ||
    link.closest( '.e-div' )?.querySelector( '.e-svg object' );
    if ( !svgObject ) return;

    const loadHandler = () => {
      const objectDoc = svgObject.contentDocument;
      const player = objectDoc?.querySelector( 'svg' )?.svgatorPlayer || null;

      if ( player && typeof player.set === 'function' ) {
        player.set( 'iterations', 1 );
        link.addEventListener( 'mouseenter', () => {
          player.play();
        } );
      }
    };

    svgObject.addEventListener( 'load', loadHandler );
  }
};

svgator.initHover();
