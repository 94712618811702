class SVGAnimationOnetime {
  constructor( selector ) {
    this.svgRows = Array.from( 
      document.querySelectorAll( selector ) 
    ).filter( 
      row => window.getComputedStyle( row ).display !== 'none' 
    );
    this.svgQueue = [];
    this.pendingSVGs = [];
    this.activeTimeout = null;
    this.handleScrollEvent = this.handleScrollEvent.bind( this );
    this.handleWindowLoad = this.handleWindowLoad.bind( this );
    window.addEventListener( 'scroll', this.handleScrollEvent );
    window.addEventListener( 'load', this.handleWindowLoad );
  }

  handleWindowLoad() {
    this.prepareSVGAnimations();
    this.handleLineAnimation();
  }

  handleScrollEvent() {
    ( async () => {
      await this.handleLineAnimation();
    } )();
  }

  prepareSVGAnimations() {
    this.svgRows.forEach( svgRow => {
      const svgatorObject = svgRow.querySelector( '.e-svg object' );
      const svgatorDocument = svgatorObject?.contentDocument || svgatorObject?.contentWindow?.document;
      const svgatorElement = svgatorDocument?.querySelector( 'svg' )?.svgatorPlayer || null;
      if ( svgatorElement ) {
        svgatorElement.pause();
        svgatorElement.seek( 0 );
        this.svgQueue.push( { 'svg': svgatorElement, 'status': true, 'el': svgRow } );
      }
    } );
  }

  isSVGInView( index ) {
    const svgTopPos = this.svgRows[index].getBoundingClientRect().top;
    const svgBottomPos = this.svgRows[index].getBoundingClientRect().bottom;
    const noOfAnimations = this.svgRows[index].closest( '.row' ).querySelectorAll( '.e-col--onetime-line-animate' );
    const animationStartingPos = ( noOfAnimations.length > 1 )? window.innerHeight : window.innerHeight / 2 ;
    if ( svgTopPos <= ( animationStartingPos ) && svgBottomPos > 0 ) {
      return index;
    }
  }

  async handleLineAnimation() {
    for ( let index = 0; index < this.svgRows.length; index++ ) {
      if ( this.isSVGInView( index ) !== undefined ) {
        await this.animateSVGs( index );
      }
    }
  }

  updatePendingSVGs() {
    this.pendingSVGs = [];
    this.svgQueue.forEach( ( svg, index ) => {
      if ( svg['status'] !== false && this.isSVGInView( index ) === index ) {
        this.pendingSVGs.push( index );
      }
    } );
  }

  async playPendingSVGs() {
    this.updatePendingSVGs();
    for ( const index of this.pendingSVGs ) {
      await this.playSVG( index );
    }
  }

  async animateSVGs( index ) {
    if ( this.svgQueue.length === 0 ) return;
    if ( !this.svgQueue[index] ) return;

    await this.playPendingSVGs();
    await this.playSVG( index );
  }

  waitForMedia( element ) {
    return new Promise( ( resolve ) => {
      const hasMedia = element.querySelector( 'img, video' );
  
      if ( hasMedia ) {
        if ( hasMedia.tagName === 'IMG' ) {
          if ( hasMedia.complete ) {
            resolve();
          } else {
            hasMedia.addEventListener( 'load', () => {
              resolve();
            } );
          }
        } else if ( hasMedia.tagName === 'VIDEO' ) {
          if ( hasMedia.readyState >= 3 ) {
            resolve();
          } else {
            hasMedia.addEventListener( 'canplaythrough', () => {
              resolve();
            } );
          }
        }
      } else {
        resolve();
      }
    } );
  }

  playSVG( index ) {
    return new Promise( ( resolve ) => {
      const player = this.svgQueue[index]['svg'];
      const element = this.svgQueue[index]['el'];
      
      this.waitForMedia( element ).then( () => {
        if ( player && this.svgQueue[index]['status'] !== false ) {
          player.play();
          player.on( 'end', () => {
            this.svgQueue[index]['status'] = false;
            player.off( 'end' );
            resolve();
          } );
        } else {
          resolve();
        }
      } );
    } );
  }
  
}

const svgAnimationOnetime = new SVGAnimationOnetime( 
  '.e-row--onetime-line-animate:has( .e-svg), .e-col--onetime-line-animate:has( .e-svg)' 
);
document.addEventListener( 'DOMContentLoaded', svgAnimationOnetime );
