function setupFormInteractions() {
  const formContainer = document.querySelector( '.e-div--forms-common' );
  if ( !formContainer ) return;

  function handleInputEvent( e ) {
    const isInputField = ['INPUT', 'TEXTAREA', 'SELECT'].includes( e.target.tagName );
    if ( isInputField && e.target.classList.contains( 'hs-input' ) ) {
      e.target.closest( '.field' ).classList.add( 'active' );
    }
  }

  function handleFieldState( e ) {
    if ( e.target.classList.contains( 'hs-input' ) ) {
      const method = e.target.value === '' ? 'remove' : 'add';
      e.target.closest( '.field' ).classList[method]( 'active' );
    }
  }

  formContainer.addEventListener( 'input', handleInputEvent );
  formContainer.addEventListener( 'change', handleInputEvent );
  formContainer.addEventListener( 'focus', handleInputEvent, true );
  formContainer.addEventListener( 'blur', handleFieldState, true );
  formContainer.addEventListener( 'focusout', handleFieldState );
}

document.addEventListener( 'DOMContentLoaded', () => {
  setupFormInteractions();
} );
