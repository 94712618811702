document.addEventListener( 'DOMContentLoaded', () => {
  const sameHeight = ( elements ) => {
    const heights = [];
    elements.forEach( ( el ) => {
      el.style.height = 'auto';
      heights.push( el.offsetHeight );
    } );
    const maxHeight = Math.max( ...heights );
    elements.forEach( ( el ) => {
      el.style.height = `${maxHeight}px`;
    } );
  };

  const addEqualHeight = () => {
    const winWidth = document.documentElement.clientWidth;
    const sectionTeam = document.querySelectorAll( '.e-stn--focus-scroll-section .swiper' );

    sectionTeam.forEach( ( section ) => {
      const childSlide = section.querySelectorAll( '.swiper-slide .e-div--top-content' );
      if ( winWidth < 767 ) {
        sameHeight( Array.from( childSlide ) );
      } else {
        childSlide.forEach( ( heading ) => {
          heading.removeAttribute( 'style' );
        } );
      }
    } );
  };

  let timer;
  window.addEventListener( 'resize', () => {
    clearTimeout( timer );
    timer = setTimeout( () => {
      addEqualHeight();
    }, 10 );
  } );

  window.addEventListener( 'load', addEqualHeight );
} );
