import Player from '@vimeo/player';

class VideoPlay {
  constructor() {
    const mediaEl = document.querySelectorAll( 'iframe, video' );
    const playButton = document.querySelectorAll( '.btn-playback' );
    const pauseButton = document.querySelectorAll( '.btn-playback--pause' );
    this.observer = new IntersectionObserver( this.handleIntersectionObserver.bind( this ) );
    mediaEl.forEach( media => {
      this.observer.observe( media );
    } );
    playButton.forEach( button => {
      button.addEventListener( 'click', () => {
        this.playMedia( button );
      } );
    } );
    pauseButton.forEach( button => {
      button.addEventListener( 'click', () => {
        this.pauseMedia( button );
      } );
    } );
  }

  playMedia( playButton ) {
    const parentEl = playButton.closest( '.e-vid' );
    const media = parentEl.querySelector( 'video, iframe' );
    const srcLink = media.getAttribute( 'src' ) || media.querySelector( 'source' )?.getAttribute( 'src' );
    const videoType = this.getVideoType( srcLink );
    this.videoPlay( media, videoType );
  }

  pauseMedia( pauseButton ) {
    const parentEl = pauseButton.closest( '.e-vid' );
    const media = parentEl.querySelector( 'video, iframe' );
    const srcLink = media.getAttribute( 'src' ) || media.querySelector( 'source' )?.getAttribute( 'src' );
    const videoType = this.getVideoType( srcLink );
    this.videoPause( media, videoType );
  }

  handleIntersectionObserver( entries ) {
    entries.forEach( entry => {
      const media = entry.target;
      const srcLink = media.getAttribute( 'src' ) || media.querySelector( 'source' )?.getAttribute( 'src' );
      const videoType = this.getVideoType( srcLink );
      if ( !entry.isIntersecting ) {
        this.videoPause( media, videoType );
        media.nextElementSibling?.classList.remove( 'disable' );
      }
    } );
  }

  videoPlay( media, videoType ) {
    if ( videoType === 'youtube' && media?.contentWindow ) {
      media.contentWindow.postMessage( '{"event":"command","func":"playVideo","args":""}', 'https://www.youtube.com' );
    } else if ( videoType === 'vimeo' ) {
      const vimeoPlayer = new Player( media );
      vimeoPlayer.play();
    } else if ( videoType === 'mp4' ) {
      media.play();
    }
    media.classList.add( 'play' );
  }

  videoPause( media, videoType ) {
    if ( !media.classList.contains( 'play' ) ) return;
  
    if ( videoType === 'youtube' && media?.contentWindow ) {
      media.contentWindow.postMessage( '{"event":"command","func":"pauseVideo","args":""}', 'https://www.youtube.com' );
    } else if ( videoType === 'vimeo' ) {
      const vimeoPlayer = new Player( media );
      vimeoPlayer.pause();
    } else if ( videoType === 'mp4' ) {
      media.play().then( () => media.pause() ).catch( console.error );
    }
    media.nextElementSibling.classList.remove( 'disable' );
    media.classList.remove( 'play' );
    media.closest( '.e-vid' ).querySelector( '.btn-playback--play' ).classList.remove( 'hide' );
    media.closest( '.e-vid' ).querySelector( '.btn-playback--pause' ).classList.add( 'hide' );
  }

  getVideoType( url ) {
    const youtubeRegex = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.?be)\/.+$/;
    const vimeoRegex = /^(https?:\/\/)?(www\.)?(vimeo\.com|player\.vimeo\.com)\/.+$/;

    if ( youtubeRegex.test( url ) ) {
      return 'youtube';
    } else if ( vimeoRegex.test( url ) ) {
      return 'vimeo';
    } else {
      return 'mp4';
    }
  }
}

const videoPlay = new VideoPlay();
document.addEventListener( 'DOMContentLoaded', videoPlay );
