const plansTbl = {
  sections: document.querySelectorAll( '.e-stn--plans-table' ),

  /**
   * Initialize popup
   * @param {Element} section 
   */
  initPopup: function( section ) {
    const toolTips = section.querySelectorAll( '.tool-tip' );
    if ( toolTips.length === 0 ) return;

    document.addEventListener( 'click', ( e ) => {
      toolTips.forEach( toolTip => {
        toolTip.classList.remove( 'active' );
      } );

      if ( e.target.classList.contains( 'icon' ) && e.target.closest( '.tool-tip' ) ) {
        e.target.closest( '.tool-tip' ).classList.add( 'active' );
      }
    } );
  },

  init: function() {
    if ( this.sections.length === 0 ) return;

    this.sections.forEach( section => {
      this.initPopup( section );
    } );
  }
};

plansTbl.init();
