class ElementObserver {
  constructor( selector ) {
    this.selector = selector;    
  }

  init() {
    const elements = document.querySelectorAll( this.selector );
    const bottomRootMargin =
      window.innerHeight > window.innerWidth ? '-8%' : '-16%';

    elements.forEach( ( element ) => {
      const observer = new IntersectionObserver(
        ( entries ) => {
          const entry = entries[0];
          if ( entry.isIntersecting ) {
            element.classList.add( 'bs-appeared' );
            observer.disconnect();
          }
        },
        {
          root: null,
          rootMargin: `0px 0px ${bottomRootMargin} 0px`,
        }
      );

      element.querySelectorAll( 'img' ).forEach( ( img ) => {
        const image = new Image();
        image.onload = () => {
          element.classList.add( 'bs-image-loaded' );
        };
        image.src = img.getAttribute( 'src' );
      } );

      observer.observe( element );
    } );
  }
}

document.addEventListener( 'DOMContentLoaded', () => {
  new ElementObserver( '.e-stn--observe' ).init();
} );
